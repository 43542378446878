<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import Stat from "./widgetcustomer";
import Transaction from './transaction';
import Sources from "./sources";

import useHttp from '@/comp-functions/useHttp'
import {ref, onMounted} from '@vue/composition-api'
import {formatDatepicker} from '@/utils/formatter'

import {
  barChartAirline
} from "./data-apex";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    Multiselect,
    PageHeader,
    DatePicker,
    Stat,
    Transaction,
    Sources
  },
  setup () {
    const { $get } = useHttp()
    const LOV = ref({
      ports: []
    })
    
    // Get account types
    const getPorts = async () => {
      const {data} = await $get({ url: 'master/port'})
      LOV.value.ports = data
    }

    onMounted(() => {
      getPorts()
     
    })

    return {
      LOV,
      getPorts,
      formatDatepicker
    }
  },
  data() {
    return {
      title: "Home - Customer",
      items: [
        {
          text: "Antar Port"
        },
        {
          text: "Home - Customer",
          active: true
        }
      ],
      barChartAirline: barChartAirline,
      value1: null,
      valueDeparturedate: "",
      valueOrigin:null,
      valueDestination:null,
      select_label: "Select"
    };
  },
  methods: {
    customLabel ({ prt_initial, prt_locationname }) {
      return `${prt_initial} – ${prt_locationname}`
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">        
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3">
                <div class="form-group position-relative">
                  <label for="validationTooltipUsername">Origin</label>
                  <multiselect id="mv_origin" name="mv_origin" :select-label="select_label" :custom-label="customLabel" v-model="valueOrigin" :options="LOV.ports"></multiselect>
                </div>
              </div>
              <div class="col-xl-3">
                <div class="form-group position-relative">
                  <label for="validationTooltipUsername">Destination</label>
                  <multiselect id="mv_destination" name="mv_destination" :select-label="select_label" :custom-label="customLabel" v-model="valueDestination" :options="LOV.ports"></multiselect>
                </div>
              </div>

              <div class="col-xl-3">
                <div class="form-group position-relative">
                  <label for="validationTooltipUsername">Departure Date</label>
                  <div class="input-group">
                    <date-picker :first-day-of-week="1" lang="en" v-model="valueDeparturedate"></date-picker>
                  </div>
                </div>
              </div>
              
              <div class="col-xl-3">
                <div class="form-group position-relative">
                  <label for="validationTooltipUsername"> </label>
                  <div class="input-group">
                    <b-button variant="primary" :to="{ name: 'apps-order-searchflightparams', params: {origin : valueOrigin, destination : valueDestination, departure_date : valueDeparturedate} }">
                      <i class="ri-search-line"></i>
                      Search Flight
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <Stat />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Top 5 Sales By Airline</h4>
            <!-- Bar Chart -->
            <apexchart
              class="apex-charts"
              height="350"
              type="bar"
              dir="ltr"
              :series="barChartAirline.series"
              :options="barChartAirline.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <Sources />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <Transaction />
      </div>
    </div>

    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-10">
                For additional requests from available schedules for extra flights or charter flights. <br/>
                Please contact us on WA or click the following link :
              </div>
              <div class="col-lg-2 text-right">
                <a href="https://api.whatsapp.com/send/?phone=6281319230767&text=Hi+Antarport%2C&app_absent=0" >
                  <span>
                    <img src="@/assets/images/whatsapp.png" alt height="50" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>